import { Controller } from '@hotwired/stimulus';
import { nanoid } from 'nanoid';

const AccordionController = class extends Controller {
  declare readonly triggerTargets: NodeListOf<HTMLElement>;
  static targets = ['trigger'];

  declare multipleValue: boolean;
  declare toggleValue: boolean;
  declare expandLabelValue: string;
  declare collapseLabelValue: string;
  static values = {
    multiple: {
      type: Boolean,
      default: true,
    },
    toggle: {
      type: Boolean,
      default: true,
    },
    expandLabel: {
      type: String,
      default: 'expand all',
    },
    collapseLabel: {
      type: String,
      default: 'collapse all',
    },
  };

  declare baseId: string;

  connect(): void {
    this.baseId = nanoid(8);

    // Create to correct aria roles and relations
    this.triggerTargets.forEach((heading: HTMLElement, index: number) => {
      const panel = heading.nextElementSibling;
      const panelId = heading.id ? heading.id : `${this.baseId}-${index + 1}`;

      // Transform heading
      const btn = document.createElement('button');
      btn.innerText = heading.innerText;

      btn.setAttribute('id', `${this.baseId}-h${index + 1}`);
      btn.setAttribute('aria-expanded', 'false');
      btn.setAttribute('aria-disabled', 'false');
      btn.setAttribute('tabindex', '0');
      btn.setAttribute('aria-controls', panelId);

      btn.addEventListener('click', (event: MouseEvent) => {
        event.preventDefault();
        this.toggle(event);
      });

      heading.removeAttribute('id');
      heading.replaceChildren(btn);

      if (panel) {
        panel.setAttribute('role', 'region');
        panel.setAttribute('hidden', 'true');
        panel.setAttribute('id', panelId);
        panel.setAttribute('aria-labelledby', `${this.baseId}-h${index + 1}`);
      }

      // Activate Accordion panel if hash matches
      if (window.location.hash.substr(1) === panelId) {
        this.open(btn);
        btn.focus();
      }
    });

    // Bind keyboard behaviors on the main accordion container
    this.element.addEventListener('keydown', (event) => this.keydownHandler(event as KeyboardEvent));

    // Minor setup: will set disabled state, via aria-disabled, to an
    // expanded/ active accordion which is not allowed to be toggled close
    if (!this.multipleValue) {
      // Get the first expanded/ active accordion
      const expanded = this.element.querySelector(
        '[data-accordion-target="trigger"] button[aria-expanded="true"]',
      );

      // If an expanded/ active accordion is found, disable
      if (expanded) {
        expanded.setAttribute('aria-disabled', 'true');
      }
    }

    if (this.multipleValue && this.toggleValue) {
      this.addSectionsToggle();
    }
  }

  keydownHandler(event: KeyboardEvent): void {
    const targetEl = event.target as HTMLElement;
    const { key } = event;

    // Page Up and Page Down
    const ctrlModifier = event.ctrlKey && ['PageUp', 'PageDown'].includes(key);

    // Is this coming from an accordion header?
    if (targetEl?.hasAttribute('aria-controls')) {
      // Up/ Down arrow and Control + Page Up/ Page Down keyboard operations
      if (['ArrowUp', 'ArrowDown'].includes(key) || ctrlModifier) {
        const index = Array.from(this.triggerTargets).indexOf(
          targetEl.parentNode as HTMLElement,
        );
        const direction = ['PageDown', 'ArrowDown'].includes(key) ? 1 : -1;
        const { length } = this.triggerTargets;
        const newIndex = (index + length + direction) % length;
        const nextButton = this.triggerTargets[newIndex]
          .firstChild as HTMLButtonElement;
        nextButton?.focus();

        event.preventDefault();
      } else if (['Home', 'End'].includes(key)) {
        const firstTriggerButton = this.triggerTargets[0]
          .firstChild as HTMLButtonElement;
        const lastTriggerButton = this.triggerTargets[
          this.triggerTargets.length - 1
        ].firstChild as HTMLButtonElement;
        // Home and End keyboard operations
        switch (key) {
          // Go to first accordion
          case 'Home':
            firstTriggerButton?.focus();
            break;
          // Go to last accordion
          case 'End':
            lastTriggerButton?.focus();
            break;
          default:
            break;
        }
        event.preventDefault();
      }
    }
  }

  toggle(event: MouseEvent): void {
    const target = event.target as HTMLButtonElement;

    // Check if the current toggle is expanded.
    const isExpanded = target.getAttribute('aria-expanded') === 'true';
    const active = this.element.querySelector(
      '[data-accordion-target="trigger"] button[aria-expanded="true"]',
    ) as HTMLButtonElement;

    // without allowMultiple, close the open accordion
    if (!this.multipleValue && active && active !== target) {
      this.close(active);
    }

    if (!isExpanded) {
      this.open(target);
    } else if (this.multipleValue && isExpanded) {
      this.close(target);
    }

    if (this.multipleValue && this.toggleValue) {
      this.changeSectionsToggle();
    }

    event.preventDefault();
  }

  open(btnEl: HTMLButtonElement): void {
    const targetId = btnEl.getAttribute('aria-controls');

    // Set the expanded state on the triggering element
    btnEl?.setAttribute('aria-expanded', 'true');
    // Show the accordion section, using aria-controls to specify the desired section
    if (targetId) {
      document.getElementById(targetId)?.removeAttribute('hidden');
    }

    // If toggling is not allowed, set disabled state on trigger
    if (!this.multipleValue) {
      btnEl?.setAttribute('aria-disabled', 'true');
    }
  }

  close(btnEl: HTMLButtonElement): void {
    const activeTargetId = btnEl?.getAttribute('aria-controls');
    // Set the expanded state on the triggering element
    btnEl.setAttribute('aria-expanded', 'false');
    // Hide the accordion sections, using aria-controls to specify the desired section
    if (activeTargetId) {
      document.getElementById(activeTargetId)?.setAttribute('hidden', '');
    }

    // When toggling is not allowed, clean up disabled state
    if (!this.multipleValue) {
      btnEl.removeAttribute('aria-disabled');
    }
  }

  addSectionsToggle(): void {
    // Define the expand/collapse all template
    const buttons = document.createElement('div');
    buttons.setAttribute('role', 'group');
    buttons.setAttribute('aria-label', 'section control');
    buttons.innerHTML = `
        <button type="button" data-action="expand">${this.expandLabelValue}</button>
        <button type="button" data-action="collapse">${this.collapseLabelValue}</button>
    `;

    // Insert the button controls
    this.element.prepend(buttons);

    // Place the click on the parent <ul>...
    buttons.addEventListener('click', (e) => {
      // ...then determine which button was the target
      const target = e.target as HTMLElement;
      const expand = target.dataset.action === 'expand';

      // Iterate over the toggle sections to switch each one's state uniformly
      Array.from(this.triggerTargets).forEach((trigger) => {
        const btn = trigger.firstChild as HTMLButtonElement;
        if (expand) {
          this.open(btn);
        } else {
          this.close(btn);
        }
      });

      this.changeSectionsToggle();
    });
    this.changeSectionsToggle();
  }

  changeSectionsToggle(): void {
    const triggerButtons = this.element.querySelectorAll(
      '[data-accordion-target="trigger"] button[aria-expanded="true"]',
    );
    const expandButton = this.element.querySelector(
      '[data-action="expand"]',
    ) as HTMLElement;
    const collapseButton = this.element.querySelector(
      '[data-action="collapse"]',
    ) as HTMLElement;

    collapseButton.hidden = triggerButtons.length === 0;
    expandButton.hidden = triggerButtons.length === this.triggerTargets.length;
  }
};

export default AccordionController;
