import { Controller } from '@hotwired/stimulus';

const ReelController = class extends Controller {
  hasGalleryTarget: boolean;
  galleryTarget: HTMLElement;
  hasItemTarget: true;
  itemTargets: HTMLElement[];
  hasControlsTarget: boolean;
  controlsTarget: HTMLElement;
  static targets = ['gallery', 'item', 'controls'];

  connect(): void {
    // touch detection
    window.addEventListener('touchstart', function touched() {
      document.body.classList.add('touch');
      window.removeEventListener('touchstart', touched, false);
    }, false);

    if (this.hasGalleryTarget) {
      if ('ResizeObserver' in window) {
        new ResizeObserver(() => {
          ReelController.toggleOverflowClass(this.galleryTarget);
        }).observe(this.element);
      }

      if ('MutationObserver' in window) {
        new MutationObserver(() => {
          ReelController.toggleOverflowClass(this.galleryTarget);
        }).observe(this.element, { childList: true });
      }

      if ('IntersectionObserver' in window) {
        const callback = function callback(slides: HTMLElement[]): void {
          Array.prototype.forEach.call(slides, (entry) => {
            entry.target.classList.remove('is-visible');
            const link = entry.target.querySelector('a');
            if (link) {
              link.setAttribute('tabindex', '-1');
            }
            if (!entry.intersectionRatio > 0) {
              return;
            }
            entry.target.classList.add('is-visible');
            if (link) {
              link.removeAttribute('tabindex', '-1');
            }
          });
        };

        const observer = new IntersectionObserver(callback, {
          root: this.galleryTarget,
          rootMargin: '-10px',
        });
        this.itemTargets.forEach((t) => observer.observe(t));
      }
    }
  }

  scrollIt(slideToShow: HTMLElement): void {
    const scrollPos = Array.prototype.indexOf.call(this.itemTargets, slideToShow) * (this.galleryTarget.scrollWidth / this.itemTargets.length);
    this.galleryTarget.scrollLeft = scrollPos;
  }

  showSlide(dir: string): void {
    const visible = this.element.querySelectorAll('.is-visible');
    const newSlide = dir === 'previous' ? visible[0].previousElementSibling : visible[0].nextElementSibling;

    if (newSlide) {
      this.scrollIt(newSlide as HTMLElement);
    }
  }

  previous(): void {
    this.showSlide('previous');
  }

  next(): void {
    this.showSlide('next');
  }

  static toggleOverflowClass = (elem: HTMLElement) => {
    elem.classList.toggle('reel__gallery--overflowing', elem.scrollWidth > elem.clientWidth);
  };
};

export default ReelController;
