const breakpoints: any = {
  xs: '0',
  sm: '576px',
  md: '740px',
  lg: '1000px',
  xl: '1400px',
};

const mediaBreakpointExists = (breakpoint: string) => breakpoint in breakpoints;

const mediaBreakpointDown = (breakpoint: string) => {
  if (mediaBreakpointExists(breakpoint)) {
    return window.matchMedia(`(max-width: ${breakpoints[breakpoint]})`).matches;
  }

  return false;
};

const mediaBreakpointUp = (breakpoint: string) => {
  if (mediaBreakpointExists(breakpoint)) {
    return window.matchMedia(`(min-width: ${breakpoints[breakpoint]})`).matches;
  }

  return false;
};

const prefersReducedMotion = () => window.matchMedia('(prefers-reduced-motion: reduce)').matches;

export { prefersReducedMotion, mediaBreakpointDown, mediaBreakpointUp };
