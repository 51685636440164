const isElement = (object) => {
  if (!object || typeof object !== 'object') {
    return false;
  }

  return typeof object.nodeType !== 'undefined';
};

const isVisible = (element: HTMLElement) => {
  if (!isElement(element) || element.getClientRects().length === 0) {
    return false;
  }

  const elementIsVisible = getComputedStyle(element).getPropertyValue('visibility') === 'visible';
  // Handle `details` element as its content may falsie appear visible when it is closed
  const closedDetails = element.closest('details:not([open])');

  if (!closedDetails) {
    return elementIsVisible;
  }

  if (closedDetails !== element) {
    const summary = element.closest('summary');
    if (summary && summary.parentNode !== closedDetails) {
      return false;
    }

    if (summary === null) {
      return false;
    }
  }

  return elementIsVisible;
};

const isDisabled = (element: HTMLElement) => {
  if (!element || element.nodeType !== Node.ELEMENT_NODE) {
    return true;
  }

  if (element.classList.contains('disabled')) {
    return true;
  }

  if (typeof element.disabled !== 'undefined') {
    return element.disabled;
  }

  return element.hasAttribute('disabled') && element.getAttribute('disabled') !== 'false';
};

const SelectorEngine = {
  find(selector: string, element = document.documentElement) {
    return Array.from(Element.prototype.querySelectorAll.call(element, selector));
  },

  findOne(selector: string, element = document.documentElement) {
    return Element.prototype.querySelector.call(element, selector);
  },

  children(element: HTMLElement, selector: string) {
    return Array.from(element.children).filter((child) => child.matches(selector));
  },

  parents(element: HTMLElement, selector: string) {
    const parents = [];
    let ancestor = element.parentElement?.closest(selector);

    while (ancestor) {
      parents.push(ancestor);
      ancestor = ancestor.parentElement?.closest(selector);
    }

    return parents;
  },

  prev(element: HTMLElement, selector: string) {
    let previous = element.previousElementSibling;

    while (previous) {
      if (previous.matches(selector)) {
        return [previous];
      }

      previous = previous.previousElementSibling;
    }

    return [];
  },

  next(element: HTMLElement, selector: string) {
    let next = element.nextElementSibling;

    while (next) {
      if (next.matches(selector)) {
        return [next];
      }

      next = next.nextElementSibling;
    }

    return [];
  },

  focusableChildren(element: HTMLElement) {
    const focusables = [
      'a',
      'button',
      'input',
      'textarea',
      'select',
      'details',
      '[tabindex]',
      '[contenteditable="true"]',
    ].map((selector) => `${selector}:not([tabindex^="-"])`).join(',');

    return this.find(focusables, element).filter((el) => !isDisabled(el) && isVisible(el));
  },
};

export default SelectorEngine;
